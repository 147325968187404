<template>
<b-row>
  <b-col cols="12" class="mb-2">
    <b-card  body-class="pt-1">
      <b-card-header header-bg-variant="transparent" class="d-flex justify-content-between">
        <div class="d-flex">
          <b-button variant="outline-secondary" @click="$emit('getNewImage')">New Image</b-button>
          <b-button variant="outline-secondary" style="margin-left: 5px" @click="$emit('resetImage')">Reset Image</b-button>
        </div>
        <h4>Inputs</h4>
        <b-button
            :disabled="step !== 'save'"
            @click="$emit('save')"
            variant="outline-danger" style="margin-left: 30px">Save And New</b-button>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="4" class="text-left">
            <div>
              <label class="h6">Event: </label> {{ inputs.Event }}
            </div>
            <div>
              <label class="h6">Time: </label> {{ inputs.Time }}
            </div>

          </b-col>
          <b-col cols="4" class="text-left">
            <div>
              <label class="h6">Place: </label> {{ inputs.Place }}
            </div>
            <div>
              <label class="h6">Outfit: </label> {{ inputs.Outfit }}
            </div>

          </b-col>

          <b-col cols="4" class="text-left">
            <div>
              <label class="h6 " style="min-width: 135px">Primary color: </label> <span :style="{ 'background-color': inputs.PrimaryColor }" class="color-rectangle border-3" ></span>
              <span><b-button @click="changeColor('primary')" variant="outline-secondary" class="copy_btn">Change</b-button></span>
            </div>

            <div>
              <label class="h6" style="min-width: 135px">Secondary color:</label> <span :style="{ 'background-color': inputs.SecondaryColor }" class="color-rectangle border-3" ></span>
              <span><b-button @click="changeColor('secondary')" variant="outline-secondary" class="copy_btn">Change</b-button></span>
            </div>

          </b-col>
        </b-row>
      </b-card-body>


    </b-card>
  </b-col>
</b-row>
</template>

<script>
import axios from "axios";

export default {
  name: "InputsBox",
  props : {
    step: {
      type: String,
      default: ''
    },
    inputs : {
      type : Object ,
      default(){
        return {}
      }
    }
  },
  created() {
    this.PLATFORM_BACKEND_API = process.env.VUE_APP_PLATFORM_BACKEND_API
  },
  data(){
    return {
      PLATFORM_BACKEND_API : null
    }
  },

  methods:{
    copy(e){
      navigator.clipboard.writeText(e)
    },
    changeColor(ColorName){

      axios.get(this.PLATFORM_BACKEND_API+'change_color').then(res => {
        if (ColorName === 'primary') {
          // eslint-disable-next-line vue/no-mutating-props
          this.inputs.PrimaryColor = res.data ;
        }else {
          // eslint-disable-next-line vue/no-mutating-props
          this.inputs.SecondaryColor = res.data ;
        }
      })

    }
  }
}
</script>

<style scoped>
.copy_btn {
  margin-left: 5px;
  font-size: 12px;
  padding: 2px 10px;
}
.border-3 {
  border-radius: 3px;
}
</style>