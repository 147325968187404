<template>
  <b-card class="text-center mt-2" >
      <b-form-group >
        <div class="d-flex text-center justify-content-between mb-2" :class="!CheeksIsActive ?  'disabled': ''">
          <div class="d-flex"><h4 class="mb-0">Cheeks</h4> <img src="@src/assets/Cheeks.png" style="width: 23px"/></div>
          <b-btn class="d-flex no-border p-0" :disabled="!CheeksEscapable" size="sm" variant="transparent">
            <b-icon-check2 v-if="CheeksIsActive" font-scale="2" style="cursor: pointer" @click="finishCheeks"  /></b-btn>
        </div>

        <b-row class="my-2 align-items-center bg-light p-2">

          <b-col cols="3" class="d-flex align-items-center ">
            <label for="lips-contour-color mx-2" >Lipstick color</label>
            <b-form-input id="lips-contour-color"
                          type="color"
                          v-model="CheeksColor"
                          style="max-width: 60px ;
                          cursor: pointer" class="mx-2 "
                          size="sm"
                          :disabled="!CheeksIsActive"
            > </b-form-input>

          </b-col>
          <b-col cols="5" class="d-flex align-items-center ">
            <b-btn
                :disabled="!CheeksIsActive"
                variant="outline-secondary" style="margin-top:auto;padding: 5px 15px " @click="sendCheeks">Apply</b-btn>

          </b-col>
        </b-row>


      </b-form-group>
    </b-card>
</template>


<script>
import {BFormInput, BIconCheck2} from 'bootstrap-vue'

export default {
  name: "CheeksCard",
  components:{
    BIconCheck2,
    BFormInput

  },
  props:{
    CheeksIsActive:{
      type: Boolean ,
      default : false
    },
    CheeksEscapable:{
      type:Boolean,
      default : false
    }


  },
  data() {
    return {
      CheeksColor : '#FF0000'


    }
  },
  methods:{
    sendCheeks(){
      this.$emit('sendCheeks', this.CheeksColor);
    },
    finishCheeks(){
      this.$emit('finishCheeks');

    }
  }
}
</script>


