<template>
  <b-card class="text-center mt-2">
    <b-form-group  v-slot="{ ariaDescribedby }">
      <div class="d-flex text-center justify-content-between mb-2 " :class=" !eyelidIsActive ? 'disabled': ''" >
      <div class="d-flex "><h4 class="mb-0">Eyelid</h4> <img src="@src/assets/eyelid.png" style="width: 50px"/></div>

      <b-btn class="d-flex no-border p-0" :disabled="!eyelidEscapable" size="sm" variant="transparent">
        <b-icon-check2 v-if="eyelidIsActive" font-scale="2" style="cursor: pointer" @click="finishType"  /></b-btn>
      </div>

      <b-form-radio-group
          id="radio-group-2"
          v-model="selectedShape"
          :aria-describedby="ariaDescribedby"
          name="radio-sub-component"
          button-variant="outline-primary"
          buttons
          :disabled="!eyelidIsActive"
          class="d-flex"
      >
        <b-form-radio v-for="option in options" :key="option.id"
                      :value="option.id"
                      class="mx-1 tr-hid-in m-auto custom-popover thin-border"
                      button-variant="light"
                      button
                      @change="optionClicked(option)"


        > <template #default>
          <b-img :src="option.img" style="width: 40px;"
                 v-b-popover="{ customClass: 'popover-main-class'}"
                 v-b-popover.bottom.hover.html="`<img src=${option.img} style='width: 100%'/>`"
          />
        </template>
        </b-form-radio>


      </b-form-radio-group>
    </b-form-group>
    <b-row class="mt-4">

      <b-col class="d-flex">
        <div v-for="color in selectedShapeColors" :key="color.name" style="margin-right: 70px" class="d-flex align-items-end">
          <div>
            <label >{{ color.text }}</label>
            <b-form-input
                :disabled="!eyelidIsActive"
                :ref="'color_'+color.id"
                v-model="optionsColor[color.id]"
                type="color"
                style="max-width: 120px ;cursor: pointer; width: 80px"
                size="sm"> </b-form-input>

          </div>
          <div @click="usePrimaryColor(color.id , inputs.PrimaryColor)" class="ready_color_div" :style="{ 'background-color': inputs.PrimaryColor }"></div>
          <div @click="usePrimaryColor(color.id , inputs.SecondaryColor)" class="ready_color_div" :style="{ 'background-color': inputs.SecondaryColor }"></div>

        </div>

        <b-btn
            :disabled="!eyelidIsActive"
            variant="outline-secondary" v-if="selectedShapeColors &&selectedShapeColors.length > 0 " style="margin-top:auto;padding: 5px 15px " @click="sendData">Apply</b-btn>
<!--        <component :is="selectedShape"/>-->
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BIconCheck2 } from 'bootstrap-vue'
// import FirstOption from "@/components/eyelid/options/FirstOption";
// import SecondOption from "@/components/eyelid/options/SecondOption";
export default {
  name: "EyelidCard",
  components:{
     BIconCheck2,
    // BPopover,
    // FirstOption ,
    // SecondOption
  },
  props:{
    eyelidIsActive:{
      type: Boolean ,
      default : false
    },
    inputs : {
      type: Object,
      default() {
        return {}
      }
    },
    eyelidEscapable: {
      type: Boolean,
      default: false ,

    },
  },
  data() {
    return {
      selectedShape: '',
      selectedShapeColors: [],
      optionsColor: [],
      selectedOptionId : 1 ,
      // PrimaryColor : '#ff0000',
      // SecondaryColor : '#0000ff',
      selectedOption :{},
      options: [
          {
            id : 1 ,
            colorCount : 3,
            text : '',
            type : '',
            img: require('@src/assets/icons/eyelid/1.png')
           },
        {
          id : 2 ,
          colorCount : 3,
          text : '',
          type : '',
          img: require('@src/assets/icons/eyelid/2.png')
        },
        {
          id : 3 ,
          colorCount : 2,
          text : '',
          type : '',
          img: require('@src/assets/icons/eyelid/3.png')
        },

        {
          id : 7 ,
          colorCount : 2,
          text : '',
          type : '',
          img: require('@src/assets/icons/eyelid/1.png')
        },
        {
          id : 8 ,
          colorCount : 3,
          text : '',
          type : '',
          img: require('@src/assets/icons/eyelid/1.png')
        },
        {
          id : 9 ,
          colorCount : 3,
          text : '',
          type : '',
          img: require('@src/assets/icons/eyelid/1.png')
        },
        {
          id : 10 ,
          colorCount : 3,
          text : '',
          type : '',
          img: require('@src/assets/icons/eyelid/1.png')
        },
      ]

    }
  },
  methods:{

    finishType(){
      this.$emit('eyelidFinished')
    },
    usePrimaryColor(colorId , colorValue){
      this.$refs[`color_${colorId}`][0].updateValue(colorValue)
    },
    sendData(){
      if (!this.optionsColor[1] || !this.optionsColor[2]){
        console.log('1 r 2 is empty');
        return ;
      }
     else if (this.selectedOption.colorCount === 3 && !this.optionsColor[3]){
       console.log('3 is empty');
       return;
      }
      let Data = {color_1 : null , color_2 : null , color_3 : null , color_4 : null, typeId : this.selectedOptionId };
      this.optionsColor.forEach((color, index)=>{
        if (index === 1) Data.color_1 = color ;
        else if (index === 2) Data.color_2 = color ;
        else if (index === 3) Data.color_3 = color ;
        else if (index === 4) Data.color_4 = color ;
      })
      this.$emit('sendEyelid',Data)
      console.log(Data);

    },
      optionClicked(option){
        // console.log(colorCount);
        this.selectedOptionId = option.id ;
        this.selectedOption = option ;
      this.getSelectedShapeColors(option.colorCount)

      },
    getSelectedShapeColors(colorCount){
        let selectedShapeColor = [] ;
      this.selectedShapeColors = [];
      this.optionsColor = [];
        for (let i = 1; i <= colorCount ; i++){
          selectedShapeColor.push({
            name : 'color_'+i ,
            text: 'Color '+i,
            id: i,

          })
        }
        this.selectedShapeColors = selectedShapeColor ;
    }
  }
}
</script>

<style scoped>
.ready_color_div {
  width: 20px;
  height: 30px;
  margin-left: 10px;
  border-radius: 2px;
  cursor: pointer;

}

</style>