<template>
    <b-card class="text-center mt-2" >
      <b-form-group >
        <div class="d-flex text-center justify-content-between mb-2" :class="!lipsIsActive ?  'disabled': ''">
          <div class="d-flex"><h4 class="mb-0">Lipstick</h4> <img src="@src/assets/lips.png" style="width: 50px"/></div>
          <b-btn class="d-flex no-border p-0" :disabled="!lipsEscapable" size="sm" variant="transparent">
          <b-icon-check2 v-if="lipsIsActive" font-scale="2" style="cursor: pointer" @click="finishLips"  /></b-btn>


        </div>

        <b-row class="my-2 align-items-center bg-light p-2">

          <b-col cols="3" class="d-flex align-items-center ">
            <label for="lips-contour-color mx-2" >Lipstick color</label>
            <b-form-input id="lips-contour-color"
                          type="color"
                          v-model="LipsColor"
                          style="max-width: 60px ;
                          cursor: pointer" class="mx-2 "
                          size="sm"
                          :disabled="!lipsIsActive"
            > </b-form-input>

          </b-col>
          <b-col cols="5" class="d-flex align-items-center ">
            <b-btn
                :disabled="!lipsIsActive"
                variant="outline-secondary" style="margin-top:auto;padding: 5px 15px " @click="sendLipsData">Apply</b-btn>

          </b-col>
        </b-row>


      </b-form-group>
    </b-card>
  </template>

  <script>
    import { BIconCheck2,BFormInput } from 'bootstrap-vue'
    export default {
      name: "LipsCart",
      components:{
        BIconCheck2,

        BFormInput
      },
      props:{
        lipsIsActive:{
          type: Boolean ,
          default : false
        },
       lipsEscapable: {
          type: Boolean,
          default: false ,

        },
      },
      data() {
        return {
          LipsColor: '#FF0000',

        }
      },
      methods:{

        sendLipsData() {
          this.$emit('sendLipsData',this.LipsColor)
        },
        finishLips(){
          this.$emit('finishLips');
        }

      }
    }
  </script>


