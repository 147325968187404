<template>
  <b-card class="text-center mt-2" >
    <div class="d-flex text-center justify-content-between mb-2" :class="!FoundationIsActive ?  'disabled': ''">
      <div class="d-flex"><h4 class="mb-0">Foundation</h4> <img src="@src/assets/foundation.png" style="width: 23px"/></div>
      <div class="d-flex" ><b-icon-check2 v-if="FoundationIsActive" font-scale="2" style="cursor: pointer"  @click="applyFoundation"/></div>

    </div>

  </b-card>
</template>

<script>
import { BIconCheck2 } from 'bootstrap-vue'

export default {
  name: "FoundationCard",
  components:{
    BIconCheck2,
  },
  props:{
    FoundationIsActive:{
      type: Boolean ,
      default : false
    }
  },
  methods:{
    applyFoundation(){
      this.$emit('applyFoundationEvent')
    },
  }
}
</script>

<style scoped>

</style>