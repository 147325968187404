<template>
  <div class="mt-0 pt-0 px-2">
    <b-navbar toggleable="lg" type="dark" variant="light">
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
      </b-collapse>
    </b-navbar>
    <inputs-box :inputs="inputs"
                :step="step"
                @getNewImage = getNewImage
                @resetImage = resetImage
                @save="saveAndNew"
    />
    <b-row>

      <b-col cols="4" style="position: relative">
        <img :src=imgPath style="width:100%" @load="processing = false"/>
        <div class="spinner-wrapper" v-if="processing">
          <b-spinner class="c-spinner"/>
        </div>

<!--        lipImagePath : {{ imgPath}}-->

      </b-col>
      <b-col cols="8" >
        <FoundationCard :foundation-is-active="step === 'foundation'"
                        @applyFoundationEvent="applyFoundation"
        />
        <eyelid-card :eyelid-is-active="step === 'eyelid'" :inputs="inputs"
                     :eyelidEscapable="eyelidEscapable"
                     @eyelidFinished="eyelidFinished"
                     @sendEyelid="sendEyelid"
        />
        <eyeliner-card :eyeliner-is-active="step === 'eyeliner'"
                       :eyelinerEscapable="eyelinerEscapable"
                       @sendEyelinerData="sendEyelinerData"
                       @finishEyeliner="finishEyeliner"

        />

        <lips-cart :lips-is-active="step=== 'lips'"
                   :lipsEscapable = lipsEscapable
                   @sendLipsData="sendLips" @finishLips="finishLips"/>
        <CheeksCard :cheeks-is-active=" step === 'cheeks'"
                    :CheeksEscapable="CheeksEscapable"

                    @sendCheeks="sendCheeks" @finishCheeks="finishCheeks"/>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputsBox from "@/components/InputsBox";
import EyelidCard from "@/components/eyelid/EyelidCard";
import EyelinerCard from "@/components/Eyeliner/EyelinerCard";
import LipsCart from "@/components/lips/LipsCart";
import CheeksCard from "@/components/cheeks/CheeksCard";
import axios from 'axios';
import FoundationCard from "@/components/Foundation/FondationCard";
export default {
  name: "HomePage",
  components: {FoundationCard, CheeksCard, LipsCart, EyelinerCard, EyelidCard, InputsBox},
  created() {
    this.PLATFORM_BACKEND_API = process.env.VUE_APP_PLATFORM_BACKEND_API;
    this.VUE_APP_PLATFORM_AI = process.env.VUE_APP_PLATFORM_AI;

  },
  mounted() {
    this.getNewInput();
    this.getNewImage();


  },
  data() {
    return {
      PLATFORM_BACKEND_API : null,
      VUE_APP_PLATFORM_AI: null,
      eyelidEscapable : false ,
      eyelinerEscapable: false,
      lipsEscapable:false,
      CheeksEscapable:false,
      imgId : 1,
      imgPath: '',
      OriginalImagePath:'',
      defaultImgPath: '@src/assets/imgs/3.jpg',
      inputs : {},
      selected: 'radio1',
      step: 'foundation',
      processing : false,
      caseId : null,
      lipImagePath: '',
      cheeksImagePath:'',
      selectedEyelidType: null,
      selectedEyelidColor1: null,
      selectedEyelidColor2: null,
      selectedEyelidColor3: null,



      selectedEyelinerWidth: null,
      selectedLipstickColor: null,
      selectedCheeksColor: null


    }

  },
  methods: {

    saveAndNew(){
      axios.post(this.PLATFORM_BACKEND_API+'store',{
        'input_data':      this.inputs,
        'event':           this.inputs.Event,
        'time':           this.inputs.Time,
        'place':           this.inputs.Place,
        'outfit':          this.inputs.Outfit,
        'primary_color':   this.inputs.PrimaryColor,
        'secondary_color': this.inputs.SecondaryColor,
        'image_id':        this.imgId ,
        'case_id' :        this.caseId,
        'eyelid_type_id':  this.selectedEyelidType,
        'eyelid_color1' :  this.selectedEyelidColor1,
        'eyelid_color2' :  this.selectedEyelidColor2,
        'eyelid_color3' :  this.selectedEyelidColor3,
        'eyeliner_width':  this.selectedEyelinerWidth,
        'lips_color'    :  this.selectedLipstickColor,
        'cheeks_color'  :  this.selectedCheeksColor,
        'final_image_path': this.imgPath
      }).then((res)=>{
       if (res.status === 200) {
         window.location.reload();
       }

      }).catch(err =>{
        console.log(err);
      })
    },

    resetImage(){
      this.imgPath = JSON.parse(JSON.stringify(this.OriginalImagePath));
      this.step = 'foundation';
    },
    getNewImage(){
      axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
      let ImgID = Math.floor(Math.random() * 49) ;
      this.imgId = ImgID ;
      this.imgPath = '/imgs/'+ImgID+'.jpg';
      this.OriginalImagePath = '/imgs/'+ImgID+'.jpg';

    },
    eyelidFinished(){
      this.step = 'eyeliner'
    },
    getNewInput(){

      axios.get(this.PLATFORM_BACKEND_API+'generate_inputs').then( res =>{
        this.inputs = res.data ;

      })
    },

    hexToRgb(hex) {
      // Remove the hash if it's present
      hex = hex.replace(/^#/, '');

      // Parse the hexadecimal values for red, green, and blue
      const bigint = parseInt(hex, 16);
      const red = (bigint >> 16) & 255;
      const green = (bigint >> 8) & 255;
      const blue = bigint & 255;

      // Return the RGB values as an object
      return [ red, green, blue ];
    }

// Example usage:

    ,
    resolveEyeLideColor(event){
      let selectedEyelidColor1 =  this.hexToRgb(event.color_1);
      let selectedEyelidColor2 =  this.hexToRgb(event.color_2);

      this.selectedEyelidColor1 = selectedEyelidColor1 ;
      this.selectedEyelidColor2 = selectedEyelidColor2 ;
      let colors =  [
        selectedEyelidColor1,
        selectedEyelidColor2
      ];
      let ThreeColorsTypesArray = [1,2,8,9,10];
      if (ThreeColorsTypesArray.includes(event.typeId)){
        let selectedEyelidColor3 =  this.hexToRgb(event.color_3);
        this.selectedEyelidColor3 = selectedEyelidColor3 ;
        colors.push(selectedEyelidColor3)
      }
      return colors;
    },
    applyFoundation(){
      this.processing = true ;
      axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
      axios.defaults.headers.common['Content-Type'] = 'application/json';
      axios.post(this.VUE_APP_PLATFORM_AI,{
        'image_path': this.imgId+'.jpg',
        'case_id': null,
        'part_id': 0,
        'type_id': null,
        'part_data': []
      }).then((res)=>{

        this.imgPath = res.data.result_img_path.replace('/var/www/html/','http://143.198.153.86/')+'?v='+ Math.floor((Math.random() * 100) + 1);
        this.caseId = res.data.new_case_id;
        this.step = 'eyelid'
      })

    },


    sendEyelid(event){
      this.processing = true ;
      this.selectedEyelidType = event.typeId ,
      axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
      axios.defaults.headers.common['Content-Type'] = 'application/json';
      axios.post(this.VUE_APP_PLATFORM_AI,{
        'image_path':  this.imgId+'/case_'+this.caseId+'/'+this.imgId+'_0.jpg',
        'case_id': this.caseId,
        'part_id': 1,
        'type_id': event.typeId,
        'colors': this.resolveEyeLideColor(event),
        'part_data': []


      }).then(res =>{
        this.imgPath = "" ;
        this.imgPath = res.data.result_img_path.replace('/var/www/html/','http://143.198.153.86/')+'?v='+ Math.floor((Math.random() * 100) + 1);
        this.eyelidEscapable = true ;
        // this.caseId = res.data.new_case_id

      }).catch(err =>{
        console.log('err')
        console.log(err)
      })
    },
    sendEyelinerData($event) {

      this.processing = true;
      this.selectedEyelinerWidth  = $event
      axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
      axios.defaults.headers.common['Content-Type'] = 'application/json';
      axios.post(this.VUE_APP_PLATFORM_AI, {
        'image_path': this.imgId+'_0'+'/case_'+this.caseId+'/'+this.imgId+'_0_1.jpg',
        'case_id': this.caseId ,
        'part_id': 2,
        'type_id': $event,
        'colors': [[0,0,0]],
        'part_data': []


      }).then(res => {
        this.imgPath = "";
        this.imgPath = res.data.result_img_path.replace('/var/www/html/', 'http://143.198.153.86/') + '?v=' + Math.floor((Math.random() * 100) + 1);
        this.eyelidEscapable = true;
        this.lipImagePath = res.data.result_img_path.replace('/var/www/html/makeup_project/dataset/','')
        this.eyelinerEscapable = true;
      }).catch(err => {
        console.log('err')
        console.log(err)
      })
    },
    finishEyeliner(){
      this.step = 'lips'
    },
    sendLips($event) {
      this.processing = true;
      let lipsRGP  = this.hexToRgb($event)
      this.selectedLipstickColor = lipsRGP
      axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
      axios.defaults.headers.common['Content-Type'] = 'application/json';
      axios.post(this.VUE_APP_PLATFORM_AI, {
        'image_path': this.lipImagePath,
        'case_id': this.caseId ,
        'part_id': 3,
        'type_id': null,
        'colors': [lipsRGP, lipsRGP],
        'part_data': []


      }).then(res => {
        this.imgPath = "";
        this.imgPath = res.data.result_img_path.replace('/var/www/html/', 'http://143.198.153.86/') + '?v=' + Math.floor((Math.random() * 100) + 1);
        this.cheeksImagePath = res.data.result_img_path.replace('/var/www/html/makeup_project/dataset/','')
        this.lipsEscapable = true;

      }).catch(err => {
        console.log('err')
        console.log(err)
      })
    },
    finishLips(){
      this.step = 'cheeks'
    },
    sendCheeks($event) {
      this.processing = true;
      this.selectedCheeksColor = this.hexToRgb($event)
      axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
      axios.defaults.headers.common['Content-Type'] = 'application/json';
      axios.post(this.VUE_APP_PLATFORM_AI, {
        'image_path': this.cheeksImagePath,
        'case_id': this.caseId ,
        'part_id': 4,
        'type_id': 1,
        'colors': [[this.selectedCheeksColor]],
        'part_data': []


      }).then(res => {
        this.imgPath = "";
        this.imgPath = res.data.result_img_path.replace('/var/www/html/', 'http://143.198.153.86/') + '?v=' + Math.floor((Math.random() * 100) + 1);
        this.CheeksEscapable = true;

      }).catch(err => {
        console.log('err')
        console.log(err)
      })
    },
    finishCheeks(){
      this.step = 'save'
    }

  },
}
</script>

<style >
.tr-hid-in input {
  visibility: hidden !important;
}
.spinner-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  background: gray;
  opacity: 0.7;
}
.c-spinner {
  margin: auto;
}
</style>