<template>
  <b-card class="text-center mt-2">
    <b-form-group  >
      <div class="d-flex text-center justify-content-between mb-2" :class="!eyelinerIsActive ?  'disabled': ''">
        <div class="d-flex align-items-end"><h4 class="mb-0">Eyeliner</h4> <img src="@src/assets/Eyeliner.png" style="width: 50px ; margin-bottom:3px"/></div>

        <b-btn class="d-flex no-border p-0"  size="sm" variant="transparent" :disabled="!eyelinerEscapable">
          <b-icon-check2 v-if="eyelinerIsActive" font-scale="2" style="cursor: pointer" @click="finishEyeliner"  /></b-btn>

      </div>

      <b-row class="my-2 align-items-center bg-light p-2">
        <b-col class="justify-content-start text-left align-items-center" cols="3">
          <label for="eyeliner-width" class="mx-2">Eyeliner size</label>
          <b-form-spinbutton id="eyeliner-width"  inline size="sm" v-model="eyeLinerWidth"
                             placeholder="Default"
                             min="1"
                             max="3"
                             :disabled="!eyelinerIsActive"
          ></b-form-spinbutton>
        </b-col>

        <b-col cols="5" class="d-flex align-items-center ">


          <b-btn
              :disabled="!eyelinerIsActive"
              variant="outline-secondary" style="margin-top:auto;padding: 5px 15px " @click="sendEyelinerData">Apply</b-btn>


        </b-col>
      </b-row>

    </b-form-group>
  </b-card>
</template>

<script>
import { BIconCheck2 } from 'bootstrap-vue'

export default {
  name: "EyelinerCard",
  components:{
    BIconCheck2,
  },
  props: {
    eyelinerIsActive: {
      type: Boolean,
      default: false
    },
    eyelinerEscapable: {
      type: Boolean,
      default: false ,

    },
  },
  data() {
    return {
      selectedLinerShape: '',
      eyeLinerWidth: 1 ,
      EyelinerColor: '#000000',
      options: {
        name : 1 ,
        text : '',
        type : ''
      }

    }
  },
  methods:{
    sendEyelinerData() {
      this.$emit('sendEyelinerData',this.eyeLinerWidth)
    },
    finishEyeliner(){
     this.$emit('finishEyeliner');
    }
  }
}
</script>

<style scoped>

</style>